import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { NAVIGATION } from 'config';
import PrivateRoute from "./PrivateRoute";

import { connect } from 'react-redux';
import { authActions, pageInfoActions, orderActions } from 'app/redux/actions';

import { getParam } from "utils/AppHelper";

//Screen imports
import { 
    Dashboard,
    About,
    Login, AuthToken, Register, ForgotPassword, Logout,
    Account, Membership, Referral, SecuritySettings, Transactions, SalesReport,
    BuyMembership, ActiveMembership, ActiveMembershipSuccess,
    PaymentPreview, PaymentResult,
    Shop,
    TermsOfService, PrivacyPolicy, PaymentPolicy, ShipmentPolicy, InspectionPolicy, ReturnPolicy, RightsProtectionPolicy, GuideVNPAY,
    VegetableCard
} from 'app/screens';

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
}

const actionCreators = {
    userUpdate: authActions.user_update,
    userUpdateToken: authActions.user_update_token,
    changeInapp: pageInfoActions.change_inapp,
    clearOrder: orderActions.clear
}

const scroll_blacklist = ['shop'];

const HashNavigation = props => {
    let page_url = useLocation();

    const [hash, setHash] = useState('');

    useEffect(() => {
        if (page_url.hash !== '') {
            setHash(page_url.hash);
        } else {
            setHash('');
        }

        return () => {
            setHash('');
        }
    }, [page_url.hash, setHash])

    useEffect(() => {
        if (hash !== '') {
            try {
                document.querySelector(hash).scrollIntoView();   
            } catch (error) {
                console.log(document.querySelector(hash), error);
            }
        } else {
            let is_scroll_blacklist = false;
            scroll_blacklist.forEach(item => {
                if (page_url.pathname.indexOf(item) !== -1) {
                    is_scroll_blacklist = true;
                }
            })

            if (is_scroll_blacklist === false) {
                window.scrollTo(0, 0);
            }
        }
    }, [hash, page_url])
}

const AppNavigator = connect(mapStateToProps, actionCreators)((props) => {
    const { auth, userUpdateToken, userUpdate, changeInapp, clearOrder } = props;
    const { user } = auth;
    
    // Fake token
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzYsInR5cGUiOjIsImlhdCI6MTcwMDgyNDAxNiwiZXhwIjoxNzAwOTEwNDE2fQ.0pmfZocegQGma2cHegJs1loQk_3A37jtwzkzO7NLBl8';

    // Real token
    const token = getParam('token');
    const inapp = getParam('inapp');
    // const inapp = '1';
    
    useEffect(() => {
        if (token || token !== '') {
            userUpdateToken(token);
            userUpdate(token);

            if (token !== user.token) {
                clearOrder();
            }
        }

        if (inapp || inapp !== '') {
            if (window.innerWidth < 768) {
                changeInapp(inapp);
            } else {
                changeInapp(0)
            }
        }

        // let json = [], text = '';
        // json.forEach(item => {
        //     text += item.id + ',';
        // })
        // console.log(text);

        return () => {
        }
    }, [token])

    return (
        <>
        <HashNavigation />
        <Routes>
            {
                (inapp || inapp !== '') && window.innerWidth < 768
                ? <Route exact path={NAVIGATION.DASHBOARD} element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                } />
                : <Route exact path={NAVIGATION.DASHBOARD} element={<Dashboard />} />
            }
            
            <Route path={NAVIGATION.VEGETABLE_CARD} element={<VegetableCard />} />

            <Route path={NAVIGATION.LOGIN} element={<Login />} />
            <Route path={NAVIGATION.AUTH_TOKEN} element={<AuthToken />} />
            <Route path={NAVIGATION.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={NAVIGATION.REGISTER} element={<Register />} />
            <Route path={NAVIGATION.LOGOUT} element={<Logout />} />

            <Route path={NAVIGATION.TERMS_OF_SERVICE} element={<TermsOfService />} />
            <Route path={NAVIGATION.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={NAVIGATION.PAYMENT_POLICY} element={<PaymentPolicy />} />
            <Route path={NAVIGATION.SHIPMENT_POLICY} element={<ShipmentPolicy />} />
            <Route path={NAVIGATION.INSPECTION_POLICY} element={<InspectionPolicy />} />
            <Route path={NAVIGATION.RETURN_POLICY} element={<ReturnPolicy />} />
            <Route path={NAVIGATION.RIGHTS_PROTECTION_POLICY} element={<RightsProtectionPolicy />} />
            <Route path={NAVIGATION.GUIDE_VNPAY} element={<GuideVNPAY />} />

            <Route path={NAVIGATION.SHOP} element={<Shop />} />
            <Route path={NAVIGATION.SHOP_ITEM_DETAIL} element={<Shop />} />

            <Route path={NAVIGATION.ACCOUNT} element={
                <PrivateRoute noInApp={true}>
                    <Account />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.MEMBERSHIP} element={
                <PrivateRoute>
                    <Membership />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.TRANSACTIONS} element={
                <PrivateRoute>
                    <Transactions />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.SALES_REPORT} element={
                <PrivateRoute noInApp={true}>
                    <SalesReport />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.REFERRAL} element={
                <PrivateRoute noInApp={true}>
                    <Referral />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.SECURITY_SETTINGS} element={
                <PrivateRoute noInApp={true}>
                    <SecuritySettings />
                </PrivateRoute>
            } />
            
            <Route path={NAVIGATION.BUY_MEMBERSHIP} element={
                <PrivateRoute noInApp={true}>
                    {
                        auth.isLogin && user.userApp.user_id === 525344 ? <BuyMembership /> : <BuyMembership />
                    }
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.ACTIVE_MEMBERSHIP} element={
                <PrivateRoute>
                    <ActiveMembership />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.ACTIVE_MEMBERSHIP_SUCCESS} element={
                <PrivateRoute>
                    <ActiveMembershipSuccess />
                </PrivateRoute>
            } />
            
            <Route path={NAVIGATION.PAYMENT_PREVIEW} element={
                <PrivateRoute noInApp={true}>
                    <PaymentPreview />
                </PrivateRoute>
            } />
            <Route path={NAVIGATION.PAYMENT_RESULT} element={
                <PrivateRoute noInApp={true}>
                    <PaymentResult />
                </PrivateRoute>
            } />
            
            <Route index path="*" element={<NoMatch />} />
        </Routes>
        </>
    )
})

const NoMatch = props => {
    return <Navigate to={NAVIGATION.DASHBOARD} replace={true} />
}

export default AppNavigator;