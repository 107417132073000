import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { orderActions, alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';

import { decodeHtmlContent } from "utils/AppHelper";

import { province_list } from './data';
import { shop_item } from './shop_items';

import { membershipService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    updateOrder: orderActions.update,
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

// const branches = [
//     {
//         name: 'LocaMart TP Giao Lưu',
//         type: 'LocaMart',
//         address: '32, Đường số 23, KĐT Thành phố Giao Lưu, Quận Bắc Từ Liêm, Thành phố Hà Nội',
//         location: '21.055258537368204, 105.77678850933307'
//     },
//     {
//         name: 'LocaMart Đỗ Mạnh Đạo',
//         type: 'LocaMart',
//         address: '165, Đỗ Mạnh Đạo, P Mỹ Xá, Thành phố Nam Định, Tỉnh Nam Định',
//         location: '20.428870381277353, 106.15407885164461'
//     },
// ]

const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const location = useLocation();
    const navigate = useNavigate();

    const [branches, setBranches] = useState([
        {
            name: 'LocaMart TP Giao Lưu',
            type: 'LocaMart',
            address: '32, Đường số 23, KĐT Thành phố Giao Lưu, Quận Bắc Từ Liêm, Thành phố Hà Nội',
            location: '21.055258537368204, 105.77678850933307'
        },
        {
            name: 'LocaMart Đỗ Mạnh Đạo',
            type: 'LocaMart',
            address: '165, Đỗ Mạnh Đạo, P Mỹ Xá, Thành phố Nam Định, Tỉnh Nam Định',
            location: '20.428870381277353, 106.15407885164461'
        },
    ]);

    useEffect(() => {
        membershipService.fetch_nearby_store().then(data => {
            console.log('?????? nearbyStoreList', data);
            if (data && data.length > 0) {
                setBranches(data)
            } else {
                setBranches([])
            }
        })

        return () => {
        }
    }, [])
    

    useEffect(() => {
        if (isLogin && pageInfo.inapp === '1') {
            navigate(NAVIGATION.MEMBERSHIP, { replace: true, state: { page_title: 'Tem Rau' } });
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    function renderShopItems(list) {
        if (!list || list.length === 0) return null;

        const renderShopItemDesktop = (item, index) => {
            return (
                <div className="col-md-3 mb-3 mb-md-4" key={'shop_item_' + index}>
                    <Link to={NAVIGATION.SHOP + '/' + item.id} className="shop-item">
                        <div className="item-image">
                            <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100"></img>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-unit" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></p>
                        </div>
                    </Link>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="shop-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="shop-slider-item-wrapper">
                            <Link to={NAVIGATION.SHOP + '/' + item.id} className="shop-item" key={'shop_item_' + index}>
                                <div className="item-image">
                                    <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="item-info">
                                    <h4 className="item-name">{item.name}</h4>
                                    <p className="item-unit" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></p>
                                </div>
                            </Link>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
                <div className="text-center">
                    <Link to={NAVIGATION.SHOP} className="btn btn-lg btn-primary btn-rounded shadow px-5 mt-4"><i className="fa-regular fa-sharp fa-shopping-bag"></i>Xem thêm sản phẩm</Link>
                </div>
            </>)
        }
        let shop_items = [];
        list.slice(0, 12).forEach((item, index) => {
            shop_items.push(renderShopItemDesktop(item, index))
        })

        return (
            <div className="row">
                {shop_items}
                <div className="col-12 text-center">
                    <Link to={NAVIGATION.SHOP} className="btn btn-lg btn-primary btn-rounded shadow px-5 mt-4"><i className="fa-regular fa-sharp fa-shopping-bag"></i>Xem thêm sản phẩm</Link>
                </div>
            </div>
        );
    }

    function renderBranchItems(list, limit) {
        if (!list || list.length === 0) return null;

        const renderBranchItemDesktop = (item, index) => {
            return (
                <div className="col-md-6 mb-3 mb-md-4" key={'branch_item_' + index}>
                    <div className="branch-item">
                        <div className="item-icon">
                            <i className="fa-regular fa-sharp fa-store fa-2x"></i>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address_full) }}></p>
                        </div>
                        <div className="text-end align-self-end">
                            <a href={`https://maps.google.com/maps?q=${item.latitude},${item.longitude}`} target="_blank" className="btn btn-sm btn-outline-primary btn-rounded px-3 mt-2"><i className="fa-regular fa-sharp fa-circle-location-arrow"></i>Mở bản đồ</a>
                        </div>
                    </div>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="branch-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="branch-slider-item-wrapper">
                            <div className="branch-item">
                                <div className="item-icon">
                                    <i className="fa-regular fa-sharp fa-store fa-lg"></i>
                                </div>
                                <div className="item-info">
                                    <h4 className="item-name">{item.name}</h4>
                                    <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address) }}></p>
                                </div>
                            </div>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
                {
                    limit && limit < list.length
                    ? <div className="text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mt-4" data-bs-toggle="modal" data-bs-target="#branchesModal"><i className="fa-regular fa-sharp fa-store"></i>Xem tất cả cửa hàng</button>
                    </div>
                    : ''
                }
            </>)
        }
        let shop_items = [];
        if (limit && limit < list.length) {
            list.slice(0, limit).forEach((item, index) => {
                shop_items.push(renderBranchItemDesktop(item, index))
            })
        } else {
            list.forEach((item, index) => {
                shop_items.push(renderBranchItemDesktop(item, index))
            })
        }

        return (
            <div className="row">
                {shop_items}
                {
                    limit && limit < shop_item.length
                    ? <div className="col-12 text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mt-4" data-bs-toggle="modal" data-bs-target="#branchesModal"><i className="fa-regular fa-sharp fa-store"></i>Xem tất cả cửa hàng</button>
                    </div>
                    : ''
                }
            </div>
        );
    }

    function renderAllBranchItems(list) {
        if (!list || list.length === 0) return null;

        const renderBranchItemDesktop = (item, index) => {
            return (
                <div className="col-md-6 mb-3 mb-md-4" key={'branch_item_' + index}>
                    <div className="branch-item">
                        <div className="item-icon">
                            <i className="fa-regular fa-sharp fa-store fa-2x"></i>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address_full) }}></p>
                        </div>
                        <div className="text-end align-self-end">
                            <a href={`https://maps.google.com/maps?q=${item.latitude},${item.longitude}`} target="_blank" className="btn btn-sm btn-outline-primary btn-rounded px-3 mt-2"><i className="fa-regular fa-sharp fa-circle-location-arrow"></i>Mở bản đồ</a>
                        </div>
                    </div>
                </div>
            )
        }
        let shop_items = [];
        list.forEach((item, index) => {
            shop_items.push(renderBranchItemDesktop(item, index))
        })

        return (
            <div className="row">
                {shop_items}
            </div>
        );
    }

    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/section-1/section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row justify-content-center text-center">
                            <div className="col-md-8">
                                <div className="ratio ratio-16x9 mb-5">
                                    <iframe src="https://www.youtube.com/embed/ngKNGsenRSc?si=5j4dy1ar6UVv_7UP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                                <img className="img-fluid w-75 mb-5" src="/public_assets/images/home/locamart-slogan.svg" alt=""></img>
                                <div className="row justify-content-center">
                                    <div className="col-6 col-md-4">
                                        <div className="slogan-item mb-4 mb-md-0">
                                            <img className="img-fluid mb-2" src="/public_assets/images/home/section-1/xanh.png" alt="" width={65}></img>
                                            <p>Xanh</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <div className="slogan-item mb-4 mb-md-0">
                                            <img className="img-fluid mb-2" src="/public_assets/images/home/section-1/tuoi.png" alt="" width={65}></img>
                                            <p>Tươi</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <div className="slogan-item mb-4 mb-md-0">
                                            <img className="img-fluid mb-2" src="/public_assets/images/home/section-1/sach.png" alt="" width={65}></img>
                                            <p>Sạch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="page-section about-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/section-2/section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">LOCAMART GIỚI THIỆU CHƯƠNG TRÌNH <br className="desktop" />&quot;MỘT TRIỆU GIA ĐÌNH VIỆT ĂN RAU SẠCH&quot;</h2>
                        <p className="sec-description">Với tầm nhìn &quot;Nâng tầm Nông sản Việt&quot;, LocaMart là hệ thống chuỗi siêu thị mini, chuyên cung cấp các loại thực phẩm sạch và đặc sản OCOP cho người tiêu dùng trên khắp Việt Nam.</p>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-5 about-text">
                                <div className="about-item mb-4">
                                    <img className="img-fluid me-4" src="/public_assets/images/home/section-2/truy-xuat-nguon-goc.png" width={65}></img>
                                    <p>Truy xuất nguồn gốc bằng <b>BlockChain</b></p>
                                </div>
                                <div className="about-item mb-4">
                                    <img className="img-fluid me-4" src="/public_assets/images/home/section-2/vietgap.png" width={65}></img>
                                    <p>Chuẩn <b>VietGAP</b></p>
                                </div>
                                <div className="about-item mb-5">
                                    <img className="img-fluid me-4" src="/public_assets/images/home/section-2/bao-hiem.png" width={65}></img>
                                    <p><b>Bảo hiểm</b> an toàn thực phẩm</p>
                                </div>
                                <div className="text-center">
                                    <Link to={NAVIGATION.BUY_MEMBERSHIP} className="btn btn-lg btn-outline-light btn-rounded px-5 mb-4 mb-md-0">Đăng ký sử dụng<br />Tem Rau LocaMart ngay</Link>
                                </div>
                            </div>
                            <div className="col-md-7 about-img">
                                <img className="img-fluid w-100" src="/public_assets/images/home/section-2/hop-rau-locamart.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="problem" className="page-section problem-section">
                <div className="container-xl">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <img className="img-fluid w-100 mb-4 mb-md-0" src="/public_assets/images/home/section-3/noi-lo-rau-sach.jpg" alt=""></img>
                        </div>
                        <div className="col-md-6">
                            <div className="sec-header mb-5 mb-md-0 text-center text-md-start">
                                <h2 className="sec-title">Nỗi lo &quot;rau sạch&quot;</h2>
                                <p className="sec-description">Các bà nội trợ vẫn đang đau đầu hàng ngày để tìm nguồn rau sạch vừa túi tiền cho bữa cơm gia đình của mình.</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 text-center mt-md-5 mb-4 mb-md-0">
                            <img className="img-fluid mb-4" width={72} src="/public_assets/images/home/section-3/thuoc-bao-ve-thuc-vat.png"></img>
                            <p>Dư lượng <br className="desktop" />thuốc bảo vệ thực vật</p>
                        </div>
                        <div className="col-6 col-md-4 text-center mt-md-5 mb-4 mb-md-0">
                            <img className="img-fluid mb-4" width={72} src="/public_assets/images/home/section-3/truy-xuat-nguon-goc.png"></img>
                            <p>Không truy xuất được <br className="desktop" />nguồn gốc</p>
                        </div>
                        <div className="col-6 col-md-4 text-center mt-md-5 mb-4 mb-md-0">
                            <img className="img-fluid mb-4" width={72} src="/public_assets/images/home/section-3/dat-do.png"></img>
                            <p>Rau quả nhập khẩu <br className="desktop" />quá đắt đỏ</p>
                        </div>
                        <div className="col-12 text-center mt-md-5">
                            <a href="#membershipCard" className="btn btn-lg btn-primary btn-rounded shadow px-5">Rau sạch mỗi ngày?</a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="shop" className="page-section shop-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Các sản phẩm LocaMart cung cấp</h2>
                        <p className="sec-description">LocaMart cung cấp đa dạng các loại thực phẩm sạch tiêu chuẩn <b>VietGAP</b> và đặc sản <b>OCOP</b> cho người tiêu dùng trên khắp Việt Nam.</p>
                    </div>
                    <div className="sec-content">
                        { renderShopItems(shop_item) }
                    </div>
                </div>
            </section>
            <section id="membershipCard" className="page-section membershipCard-section">
                <div className="container-xl">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <div className="sec-header text-center">
                                <h2 className="sec-big-title">TEM RAU</h2>
                                <h2 className="sec-title">Rau sạch mỗi ngày</h2>
                                <p className="sec-description">Đăng ký Tem Rau để dễ dàng tiếp cận với nguồn rau sạch hàng ngày từ vườn rau xanh <b>chuẩn VietGAP</b> của LocaMart.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <img className="img-fluid w-100 mt-4 mt-md-0" src="/public_assets/images/home/section-4/the-rau.png" alt=""></img>
                        </div>
                        <div className="col-12">
                            <div className="sec-header text-center mt-5">
                                <p className="sec-description">Chỉ cần bỏ ra <b>3.000.000 VNĐ mỗi năm</b> để tận hưởng rau sạch Mộc Châu/Đà Lạt - <b>chất lượng tương đương</b> rau nhập khẩu</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="membercard-item market">
                                <h3>Ngoài thị trường</h3>
                                <div className="text-center">
                                    <p className="price">
                                        7.000.000 VNĐ/năm<br />
                                        ≈ 20.000 VNĐ/ngày
                                    </p>
                                </div>
                                <ul className="membercard-list market">
                                    <li>Không truy xuất được nguồn gốc</li>
                                    <li>Không đảm bảo</li>
                                    <li>Không tuân theo quy chuẩn</li>
                                </ul>
                                <div className="footer-img">
                                    <img className="img-fluid w-100" src="/public_assets/images/home/section-4/footer-img-market.png"></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="membercard-item locamart">
                                <h3 className="text-primary">Tem Rau LocaMart</h3>
                                <div className="text-primary text-center">
                                    <p className="price">
                                        <b>3.000.000</b> VNĐ/năm<br />
                                        ≈ <b>8.000</b> VNĐ/ngày
                                    </p>
                                </div>
                                <div className="text-center mb-5">
                                    <Link to={NAVIGATION.BUY_MEMBERSHIP} className="btn btn-primary btn-rounded shadow btn-lg w-75">Mua ngay</Link>
                                </div>
                                <ul className="membercard-list locamart">
                                    <li>Được dùng tối đa 50 D-Loca Vouchers <b>tương ứng với 1.000.000 VNĐ</b></li>
                                    <li>Truy xuất nguồn gốc ứng dụng <b>công nghệ Blockchain</b></li>
                                    <li>Bảo hiểm An toàn thực phẩm <b>10.000.000 VNĐ</b></li>
                                    <li>VietGap &amp; Global Gap</li>
                                    <li>Tặng 100 Voucher tiêu dùng 20k ~ <b>2.000.000 VNĐ</b></li>
                                    <li>Tặng Voucher sản phẩm đặc sản OCOP <b>1.000.000 VNĐ</b></li>
                                </ul>
                                <div className="footer-img">
                                    <img className="img-fluid w-100" src="/public_assets/images/home/section-4/footer-img-locamart.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="branches" className="page-section branches-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/section-1/section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Hệ thống phân phối</h2>
                        <p className="sec-description">Hệ thống phân phối chuyên nghiệp và rộng khắp toàn quốc</p>
                    </div>
                    <div className="sec-content">
                        { renderBranchItems(branches, 8) }
                    </div>
                </div>

                {/* branchesModal */}
                <div className="modal fade" id="branchesModal" tabIndex="-1" aria-labelledby="branchesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="branchesModalLabel">Hệ thống phân phối</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                { renderAllBranchItems(branches) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="affiliate" className="page-section affiliate-section">
                <div className="container-xl">
                    <div className="affiliate-header-wrapper">
                        <div className="sec-header text-center">
                            <h2 className="sec-big-title text-primary">GIỚI THIỆU TEM RAU</h2>
                            <h2 className="sec-title text-warning">Cùng LocaMart trao sức khỏe đến mọi nhà</h2>
                            <p className="sec-description">Giới thiệu Tem Rau cho người thân, bạn bè cùng sử dụng ngay để trao sự an tâm và nhận lại những quyền lợi hấp dẫn!</p>
                        </div>
                        <img className="img-fluid w-100 affiliate-header-img" src="/public_assets/images/home/section-5/TraoTayTemRau.png"></img>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-4">
                                <img className="img-fluid w-100 mb-4 mb-md-0" src="/public_assets/images/home/section-5/ThungRau2.png"></img>
                            </div>
                            <div className="col-md-5 text-center">
                                <h3 className="text-warning mb-4" style={{ fontWeight: '800' }}>Giới thiệu Tem Rau ngay <br className="desktop" />chỉ với 05 bước!</h3>
                                <button className="btn btn-outline-warning btn-lg btn-rounded btn-200">GIỚI THIỆU NGAY</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="register" className="page-section register-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title text-primary">Đăng ký mua Tem Rau</h2>
                        <p className="sec-description text-primary">Cùng LocaMart thưởng thức rau sạch mỗi ngày</p>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-5">
                                <RegisterForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const RegisterForm = connect(mapStateToProps, actionCreators)(props => {
    const { auth, order, pageInfo, updateOrder, alertSuccess, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const navigate = useNavigate();

    const [membershipForm, setMembershipForm] = useState({
        ref_id: '',
        full_name: order.full_name || '',
        phone: order.phone || '',
        province_id: order.province_id || '',
        province_text: order.province_text || '',
        district_id: order.district_id || '',
        district_text: order.district_text || '',
        address: order.address || ''
    })

    const [districtList, setDistrictList] = useState(() => {
        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            let district_list = province_list.filter(item => item.Id === membershipForm.province_id);
            if (district_list.length > 0) {
                return district_list[0].Districts;
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const [dLocaVoucherNumber, setDLocaVoucherNumber] = useState(membershipForm.payment_method === 'POINTANDDLOCAVOUCHER' ? 50 : 0);

    const [subtotalNumber, setSubtotalNumber] = useState(membershipForm.payment_method === 'POINTANDDLOCAVOUCHER' ? 83.3 : 125);

    const [loading, setLoading] = useState(false);
    const [errorHighlight, setErrorHighlight] = useState([]);

    useEffect(() => {
        updateOrder(membershipForm);
        // validateForm();
        if (errorHighlight.length > 0) {
            validateForm();
        }

        return () => {
        }
    }, [membershipForm, updateOrder])

    useEffect(() => {
        if (membershipForm.payment_method === 'POINTANDDLOCAVOUCHER') {
            setDLocaVoucherNumber(50);
            setSubtotalNumber(83.3);
        } else {
            setDLocaVoucherNumber(0);
            setSubtotalNumber(125);
        }

        return () => {
        }
    }, [membershipForm.payment_method, setDLocaVoucherNumber, setSubtotalNumber])
    

    function renderProvinceOptions(list) {
        if (!list || list.length === 0) return null;

        let provinces = [];
        list.forEach((item, index) => {
            provinces.push(
                <option value={item.Id} key={'province_option_' + index}>{item.Name}</option>
            )
        })

        return provinces;
    }

    function handleProvinceSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        if (!id || id === '') {
            setDistrictList([]);
            return null;
        };

        if (id !== order.province_id) {
            setMembershipForm(form => {
                return {
                    ...form,
                    district_id: '',
                    district_text: '',
                    province_id: id,
                    province_text: text
                }
            });
        }

        // console.log(id, index, text);

        let district_list = province_list.filter(item => item.Id === id);
        if (district_list.length > 0) {
            setDistrictList(district_list[0].Districts);   
        }
    }

    function renderDistrictOptions(list) {
        if (!list || list.length === 0) return null;

        let districts = [];
        list.forEach((item, index) => {
            districts.push(
                <option value={item.Id} key={'district_option_' + index}>{item.Name}</option>
            )
        })

        return districts;
    }

    function handleDistrictSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                district_id: id,
                district_text: text
            }
        });
    }

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setMembershipForm(form => ({
            ...form,
            [input_name]: value
        }))
    }

    function validateForm() {
        const form_field = [
            {
                field_id: 'full_name',
                field_name: 'Họ và tên'
            },
            {
                field_id: 'phone',
                field_name: 'Số điện thoại'
            },
            {
                field_id: 'province_id',
                field_name: 'Tỉnh/Thành phố'
            },
            {
                field_id: 'district_id',
                field_name: 'Quận/Huyện'
            },
            {
                field_id: 'address',
                field_name: 'Địa chỉ nhận hàng'
            }
        ]

        let error_field = [];
        form_field.forEach(item => {
            if (!membershipForm[item.field_id] || membershipForm[item.field_id] === '') {
                error_field.push(item.field_id);
            }
        })

        if (error_field.length > 0) {
            let text = '';

            if (error_field.length < 3) {
                error_field.forEach(item => {
                    try {
                        let field = form_field.filter(field => field.field_id === item)[0];
                        text += ', ' + field.field_name;                        
                    } catch (error) {
                        //
                    }
                });
            } else {
                text = '  Các trường thông tin';
            }
            setErrorHighlight(error_field);
            return text;

        } else {
            return '';
        }
    }

    function proceedToPayment(e) {
        e.preventDefault();

        setLoading(true);

        let validate = validateForm();
        if (validate !== '') {
            setLoading(false);
            alertError(validate.slice(2) + ' không được để trống!');
        } else {
            setLoading(false);
            navigate(NAVIGATION.BUY_MEMBERSHIP);
        }
    }

    function errorHighlightCheck(field) {
        return errorHighlight.indexOf(field) !== -1 ? 'is-invalid' : '';
    }

    return (
        <div className="row register-form">
            <div className="col-12">
                <div className="form-floating mb-3">
                    <input type="text" className={`form-control ${ errorHighlightCheck('full_name') }`} id="full_name" placeholder="Họ và tên" defaultValue={membershipForm.full_name} onChange={e => handleFormChange(e, 'full_name')}></input>
                    <label htmlFor="fullname">Họ và tên</label>
                </div>
            </div>
            <div className="col-12">
                <div className="form-floating mb-3">
                    <input type="text" className={`form-control ${ errorHighlightCheck('phone') }`} id="phone" placeholder="Số điện thoại" defaultValue={membershipForm.phone} onChange={e => handleFormChange(e, 'phone')}></input>
                    <label htmlFor="phone">Số điện thoại</label>
                </div>
            </div>
            <div className="col-md-6 pe-md-2">
                <div className="form-floating mb-3">
                    <select className={`form-select ${ errorHighlightCheck('province_id') }`} id="province_id" aria-label="Tỉnh/Thành phố" defaultValue={membershipForm.province_id} onChange={handleProvinceSelect}>
                        <option value="">Vui lòng chọn</option>
                        {renderProvinceOptions(province_list)}
                    </select>
                    <label htmlFor="province_id">Tỉnh/Thành phố</label>
                </div>
            </div>
            <div className="col-md-6 ps-md-2">
                <div className="form-floating mb-3">
                    <select className={`form-select ${ errorHighlightCheck('district_id') }`} id="district_id" aria-label="Quận/Huyện" value={membershipForm.district_id} onChange={handleDistrictSelect}>
                        <option value="">Vui lòng chọn</option>
                        {renderDistrictOptions(districtList)}
                    </select>
                    <label htmlFor="district_id">Quận/Huyện</label>
                </div>
            </div>
            <div className="col-12">
                <div className="form-floating mb-3">
                    <input type="text" className={`form-control ${ errorHighlightCheck('address') }`} id="address" placeholder="Địa chỉ nhận hàng" defaultValue={membershipForm.address} onChange={e => handleFormChange(e, 'address')}></input>
                    <label htmlFor="address">Địa chỉ nhận hàng</label>
                </div>
            </div>
            <div className="col-12">
                <button className="btn btn-primary btn-lg btn-rounded btn-200 w-100 mt-4" onClick={proceedToPayment} disabled={loading ? true : false}>Tiếp tục</button>
            </div>
        </div>
    )
})

export {
    Dashboard
}