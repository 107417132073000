import React from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    if (pageInfo.inapp && pageInfo.inapp === '1') {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top shadow top-navbar">
                <div className="container-xl">
                    <button className="navbar-toggler ms-auto order-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                        <i className="far fa-bars"></i>
                    </button>
                    <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                        <img src="/public_assets/images/header-logo.svg" alt=""></img>
                    </Link>
                    <div className="collapse navbar-collapse order-3" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto me-auto pt-3 pt-md-0">
                            <li className="nav-item">
                                <NavLink to={NAVIGATION.MEMBERSHIP} className="nav-link" state={{ page_title: 'Tem Rau' }}>Tem Rau</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to={NAVIGATION.ACCOUNT} className="nav-link" state={{ page_title: 'Thông tin tài khoản' }}>Thông tin tài khoản</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink to={NAVIGATION.SALES_REPORT} className="nav-link" state={{ page_title: 'Báo cáo doanh thu' }}>Báo cáo doanh thu</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink to={NAVIGATION.SECURITY_SETTINGS} className="nav-link" state={{ page_title: 'Mã PIN giao dịch' }}>Mã PIN giao dịch</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={NAVIGATION.REFERRAL} className="nav-link" state={{ page_title: 'Giới thiệu bạn bè' }}>Giới thiệu bạn bè</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <button className="nav-link" onClick={e => { window.location.reload() }}>Cập nhật ứng dụng</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top shadow top-navbar">
            <div className="container-xl">
                <button className="navbar-toggler me-auto order-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-2" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={40}></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-auto pt-3 pt-md-0">
                        <li className="nav-item">
                            <a href={`${window.location.origin}#about`} className="nav-link">Về LocaMart</a>
                        </li>
                        <li className="nav-item">
                            <a href={`${window.location.origin}#membershipCard`} className="nav-link">Tem Rau</a>
                        </li>
                        <li className="nav-item">
                            <Link to={NAVIGATION.BUY_MEMBERSHIP} className="nav-link">Đăng ký</Link>
                        </li>
                        <li className="nav-item">
                            <a href={`${window.location.origin}#contact`} className="nav-link">Liên Hệ</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-auto order-3 order-lg-4">
                    <li className="nav-item dropdown navbar-user-dropdown">
                        <button className="nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/profiles/profile-1.png" alt=""></img>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="navbarDropdown">
                        {
                            isLogin
                            ? <>
                                <li><div className="navbar-user">
                                    <div className="navbar-user-avatar">
                                        <img src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/profiles/profile-1.png" alt=""></img>
                                    </div>
                                    <div className="navbar-user-info">
                                        <p className="navbar-user-fullname">{user.userApp.full_name}</p>
                                        <p className="navbar-user-username">{user.userApp.email ? user.userApp.email : user.userApp.mobile ? user.userApp.mobile : ''}</p>
                                    </div>
                                </div></li>
                                <li><hr className="dropdown-divider"></hr></li>
                                <li><Link to={NAVIGATION.MEMBERSHIP} className="dropdown-item"><i className="fa-light fa-sharp fa-fw fa-user-circle"></i>{text.top_nav_bar.account[lang]}</Link></li>
                                <li><Link to={NAVIGATION.LOGOUT} className="dropdown-item"><i className="fa-light fa-sharp fa-fw fa-sign-out"></i>{text.top_nav_bar.logout[lang]}</Link></li>
                            </>
                            : <>
                                <li><Link to={NAVIGATION.LOGIN} className="dropdown-item"><i className="fa-light fa-sharp fa-fw fa-sign-in"></i>{text.top_nav_bar.login[lang]}</Link></li>
                            </>
                        }
                        </ul>                      
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <footer className="site-footer">
            <div className="container-xl">
                <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-start">
                        <img height={64} src="/public_assets/images/home/locamart-slogan.svg" alt="" className="mb-5 mb-md-0"></img>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <a href="#" className="text-white"><i className="fa-light fa-sharp fa-globe fa-2xl"></i></a>
                        <a href="tel:1900886655" target="_blank" className="text-white mx-5"><i className="fa-light fa-sharp fa-mobile fa-2xl"></i></a>
                        <a href="https://www.facebook.com/locamosvn" target="_blank" className="text-white"><i className="fa-light fa-sharp fa-message-quote fa-2xl"></i></a>
                    </div>
                </div>
                <div className="row justify-content-center footer-policy-links mt-5">
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.VEGETABLE_CARD} className="btn btn-link text-white">Tem Rau</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.PRIVACY_POLICY} className="btn btn-link text-white">Chính sách bảo mật</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.PAYMENT_POLICY} className="btn btn-link text-white">Chính sách thanh toán</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.SHIPMENT_POLICY} className="btn btn-link text-white">Chính sách vận chuyển</Link>
                    </div>
                </div>
                <div className="row justify-content-center footer-policy-links">
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.INSPECTION_POLICY} className="btn btn-link text-white">Chính sách kiểm hàng</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.RETURN_POLICY} className="btn btn-link text-white">Chính sách đổi trả</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.RIGHTS_PROTECTION_POLICY} className="btn btn-link text-white">CS bảo đảm quyền lợi</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.GUIDE_VNPAY} className="btn btn-link text-white">HD thanh toán VNPAY</Link>
                    </div>
                </div>
                {/* <div className="footer-brand">{pageInfo.company_shortcode}</div>
                <div className="row">
                    <div className="col-sm-6">
                        <h5 className="mb-3">{pageInfo.company_name}</h5>
                        <ul className="footer-links site-footer-company-info">
                            <li>{pageInfo.address}</li>
                            {pageInfo.address_line2 ? <li>{pageInfo.address_line2}</li> : null}
                            <li>Hotline: <a href={`tel:${pageInfo.phone_number}`}>{pageInfo.phone_number}</a></li>
                            <li>Email: <a href={`mailto:${pageInfo.email}`}>{pageInfo.email}</a></li>
                            <li>Số giấy chứng nhận ĐKKD: {pageInfo.company_registration_number}</li>
                            <li>Người đại diện theo pháp luật: {pageInfo.company_representative_person}</li>
                            <li>Chức vụ: {pageInfo.company_representative_position}</li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">ĐƯỜNG DẪN</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PAYMENT_POLICY}>{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.WEBSITE_OWNER}>{text.footer.links.website_owner[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">THÔNG TIN</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.CONTACT_US}>{text.footer.information.contact[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.ABOUT_US}>{text.footer.information.about[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.FAQS}>{text.footer.information.faqs[lang]}</Link></li>
                            <li><Link to={NAVIGATION.KEYWORDS}>{text.footer.information.keywords[lang]}</Link></li>
                        </ul>
                    </div>
                </div> */}
            </div>
            {/* <div id="copyright" className="site-copyright">
                <div className="text-center">{pageInfo.company_shortname} &copy; {pageInfo.company_foundation_year}</div>
            </div> */}
        </footer>
    );
})

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}