import { orderConstants } from 'app/redux/constants';

const initialState = {
    ref_id: '',
    ref_info: null,
    user_id: '',
    token: '',
    full_name: '',
    phone: '',
    province_id: '',
    province_text: '',
    district_id: '',
    district_text: '',
    address: '',
    store_id: null,
    store_name: '',
    null_store_id: false,
    delivery_day: '',
    membercard_id: '',
    membercard_value: '',
    payment_method: 'TRANSFER',
    use_discount: false,
    membership_id: '',
    membership_package: '1',
    bank_account_transfer: null,
    payment_transaction: null,
    payment_image_url: '',
    status: 'ORDERING'
}

export function order(state = initialState, action) {
	switch (action.type) {
		case orderConstants.UPDATE:
            return {
                ...state,
                ...action.formData
            }

        case orderConstants.CLEAR:
            return initialState;
            
		default:
			return state
	}
}