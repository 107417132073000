import React from 'react';
import './ContentPages.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';

import { NAVIGATION } from 'config';

import { ScreenWithNavBarAndFooter } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const TermsOfService = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;

    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} Terms of Use</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#theService">The {pageInfo.company_shortname} Service Provided by Us</a></li>
                            <li><a href="#yourUseOfService">Your Use of the {pageInfo.company_shortname} Service</a></li>
                            <li><a href="#problemsAndDisputes">Problems and Disputes</a></li>
                            <li><a href="#aboutTheseTerms">About these Terms</a></li>
                        </ol>
                        <hr />
                        <div id="introduction">
                            <h2>1. Introduction</h2>
                            <p>
                                Please read these Terms of Use (these "Terms") carefully as they govern your use of (which includes access to) {pageInfo.company_shortname}&apos;s personalized services for publishing virtual tours and other content, including all of our websites and software applications that incorporate or link to these Terms (collectively, the &quot;{pageInfo.company_shortname} Service&quot;) and any photos, texts, or other material that is made available through the {pageInfo.company_shortname} Service (the &quot;Content&quot;).<br />
                                Use of the {pageInfo.company_shortname} Service may be subject to additional terms and conditions presented by {pageInfo.company_shortname}, which are hereby incorporated by this reference into these Terms.<br />
                                By signing up for, or otherwise using, the {pageInfo.company_shortname} Service, you agree to these Terms. If you do not agree to these Terms, then you must not use the {pageInfo.company_shortname} Service or access any Content.
                            </p>
                            <h5 className="text-primary">Service Provider</h5>
                            <p>These Terms are between you and {pageInfo.company_shortname}.</p>
                            <h5 className="text-primary">Age and eligibility requirements</h5>
                            <p>In order to use the {pageInfo.company_shortname} Service and access any Content, you need to (1) be 13 years of age (or the equivalent minimum age in your home country) or older, (2) have parent or guardian consent if you are a minor in your home country; (3) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws, and (4) reside in a country where the Service is available. You also promise that any registration information that you submit to {pageInfo.company_shortname} is true, accurate, and complete, and you agree to keep it that way at all times If you are a minor in your home country, your parent or guardian will need to enter into these Terms on your behalf. You can find additional information regarding minimum age requirements in the registration process. If you do not meet the minimum age requirements then {pageInfo.company_shortname} will be unable to register you as a user.</p>
                        </div>
                        <div id="theService">
                            <h2>2. The {pageInfo.company_shortname} Service Provided by Us</h2>
                            <h5 className="text-primary">{pageInfo.company_shortname} Service Options</h5>
                            <p>We provide certain {pageInfo.company_shortname} Service options. We may also offer special promotional plans, memberships, or services, including offerings of third-party products and services. We are not responsible for the products and services provided by such third parties.</p>
                            <h5 className="text-primary">Third-Party Applications, Devices and Open Source Software</h5>
                            <p>The {pageInfo.company_shortname} Service may be integrated with, or may otherwise interact with, third-party applications, websites, and services (&quot;Third-Party Applications&quot;) and third-party personal cameras and other devices (&quot;Devices&quot;). Your use of such Third-Party Applications and Devices may be subject to additional terms, conditions and policies provided to you by the applicable third party. {pageInfo.company_shortname} does not guarantee that Third-Party Applications and Devices will be compatible with the {pageInfo.company_shortname} Service.</p>
                            <h5 className="text-primary">Service Limitations and Modifications</h5>
                            <p>We use reasonable care and skill to keep the {pageInfo.company_shortname} Service operational and to provide you with a personalized experience. However, our service offerings and their availability may change from time to time and subject to applicable laws, without liability to you; for example:</p>
                            <ul>
                                <li>The {pageInfo.company_shortname} Services may experience temporary interruptions due to technical difficulties, maintenance or testing, or updates, including those required to reflect changes in relevant laws and regulatory requirements.</li>
                                <li>We aim to evolve and improve our Services constantly, and we may modify, suspend, or stop (permanently or temporarily) providing all of part of the {pageInfo.company_shortname} Service (including particular functions, features, and promotional offerings).</li>
                                <li>{pageInfo.company_shortname} has no obligation to provide any specific content through the {pageInfo.company_shortname} Service, and {pageInfo.company_shortname} or the applicable owners may remove photos, virtual tours and other Content without notice.</li>
                            </ul>
                            <p>{pageInfo.company_shortname} has no liability to you, nor any obligation to provide a refund to you, in connection with internet or other service outages or failures that are caused by the <span className="text-primary">actions of government authorities, other third parties or events beyond our control.</span></p>
                        </div>
                        <div id="yourUseOfService">
                            <h2>3. Your Use of the {pageInfo.company_shortname} Service</h2>
                            <h5 className="text-primary">Creating a {pageInfo.company_shortname} account</h5>
                            <p>
                                You may need to create a {pageInfo.company_shortname} account to use all or part of the {pageInfo.company_shortname} Service. Your username and password are for your personal use only and should be kept confidential. You understand that you are responsible for all use (including any unauthorized use) of your username and password. Notify our Customer Service team immediately if your username or password is lost or stolen, or if you believe there has been unauthorized access to your account.<br />
                                {pageInfo.company_shortname} may reclaim, or require you to change, your username for any reason.
                            </p>
                            <h5 className="text-primary">Your rights to use the {pageInfo.company_shortname} Service</h5>
                            <h5 className="text-primary">User Guidelines</h5>
                            <p>We&apos;ve established guidelines for using the {pageInfo.company_shortname} Service, to make sure the {pageInfo.company_shortname} Service stays enjoyable for everyone (&quot;{pageInfo.company_shortname} User Guidelines&quot;). In using the {pageInfo.company_shortname} Service, you must comply with the <span className="text-primary">{pageInfo.company_shortname} User Guidelines</span>, as well as all applicable laws, rules, and regulation, and respect the intellectual property, privacy, and other rights of third parties.</p>
                        </div>
                        <div id="problemsAndDisputes">
                            <h2>4. Problems and Disputes</h2>
                            <h5 className="text-primary">Suspending and terminating the {pageInfo.company_shortname} Services</h5>
                            <p>These Terms will continue to apply to you until terminated by either you or {pageInfo.company_shortname}. {pageInfo.company_shortname} may terminate these Terms (including any additional terms and conditions incorporated herein) or suspend your access to the {pageInfo.company_shortname} Service at any time if we believe you have breached any of these Terms, if we stop providing the {pageInfo.company_shortname} Service or any material component thereof on reasonable notice to you, or as we believe necessary to comply with applicable law. If you or {pageInfo.company_shortname} terminate these Terms, or if {pageInfo.company_shortname} suspends your access to the {pageInfo.company_shortname} Service, you agree that {pageInfo.company_shortname} shall, subject to applicable laws, have no liability or responsibility to you.</p>
                            <h5 className="text-primary">Third Party Rights</h5>
                            <p>You acknowledge and agree that the owners of the Content and certain distributors (such as app store providers) are intended beneficiaries of these Terms and have the right to enforce these Terms directly against you. Other than as set out in this section, these Terms are not intended to grant rights to anyone except you and {pageInfo.company_shortname}, and in no event shall these Terms create any third-party beneficiary rights.</p>
                            <h4 className="text-primary">Indemnification</h4>
                            <p>You agree to indemnify and hold {pageInfo.company_shortname} harmless from and against any reasonably foreseeable direct losses, damages, and reasonable expenses (including reasonable attorney fees and costs) suffered or incurred by {pageInfo.company_shortname} arising out of or related to: (1) your breach of any of these Terms (including any additional {pageInfo.company_shortname} terms and conditions incorporated herein); (2) any User Content you post or otherwise contribute; (3) any activity in which you engage on or through the {pageInfo.company_shortname} Service; and (4) your violation of any law or the rights of a third party.</p>
                            <h4 className="text-primary">Governing Law, Mandatory Arbitration and Venue</h4>
                            <h5 className="text-primary">Governing Law / Jurisdiction</h5>
                            <p>
                                Unless otherwise required by mandatory laws in your country of residence, the Agreements (and any non-contractual disputes/claims arising out of or in connection with them) are subject to the laws of the state or country listed below, without regard to choice or conflicts of law principles.<br />
                                Further, you and {pageInfo.company_shortname} agree to the jurisdiction of the courts listed below to resolve any dispute, claim, or controversy that arises in connection with the Agreements (and any non-contractual disputes/claims arising out of or in connection with them), except where under applicable mandatory laws, you can choose to bring legal proceedings in your country of residence, or we are required to only bring legal proceedings in your country of residence.
                            </p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-warning text-dark">
                                            <th>Country or region</th>
                                            <th>Choice of Law</th>
                                            <th>Jurisdiction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>All other countries and regions in which {pageInfo.company_shortname} is available.</td>
                                            <td>Sweden</td>
                                            <td>Exclusive; Courts of Sweden</td>
                                        </tr>
                                        <tr>
                                            <td>Bulgaria, Cyprus, Estonia, France, Hong Kong, Latvia, Lithuania, Luxembourg, Malta, Monaco, Norway, Philippines, Portugal, Slovakia, Spain, Turkey</td>
                                            <td>Laws of Sweden</td>
                                            <td>Non-exclusive; Courts of Sweden</td>
                                        </tr>
                                        <tr>
                                            <td>Brazil</td>
                                            <td>Laws of Brazil</td>
                                            <td>Exclusive; State and Federal Courts of São Paulo, State of São Paulo, Brazil</td>
                                        </tr>
                                        <tr>
                                            <td>Canada</td>
                                            <td>Not applicable to residents of Quebec: Laws of the Province of OntarioResidents of Quebec: Laws of the Province of Quebec, Canada</td>
                                            <td>Not applicable to residents of Quebec: Exclusive other than for the purpose of enforcing judgements; Courts of Ontario, CanadaResidents of Quebec: Courts of Quebec, Canada</td>
                                        </tr>
                                        <tr>
                                            <td>Argentina, Bolivia, Chile, Colombia, Costa Rica, Dominican Republic, Ecuador, El Salvador, Guatemala, Honduras, Nicaragua, Panama, Paraguay, Peru, Uruguay</td>
                                            <td>State of California, United States</td>
                                            <td>Exclusive; State and Federal Courts of San Francisco County, CA or New York, NY</td>
                                        </tr>
                                        <tr>
                                            <td>United Kingdom</td>
                                            <td>Laws of England and Wales</td>
                                            <td>Exclusive</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div id="aboutTheseTerms">
                            <h2>5. About these Terms</h2>
                            <p>Under applicable law, you may have certain rights that can&apos;t be limited by a contract. These terms are in no way intended to restrict those rights.</p>
                            <h5 className="text-primary">Changes</h5>
                            <p>We may make changes to these Terms (including any additional {pageInfo.company_shortname} terms and conditions incorporated by reference herein) from time to time by notifying you of such changes by any reasonable means (before they take effect), including by posting a revised Agreement on the applicable {pageInfo.company_shortname} Service (provided that, for material changes, we will seek to supplement such notice by email, an in-service pop-up message, or other means). Any such changes will not apply to any dispute between you and us arising prior to the date on which we posted the revised Terms, or other {pageInfo.company_shortname} terms and conditions, incorporating such changes, or otherwise notified you of such changes. Your use of the {pageInfo.company_shortname} Service following any changes to these Terms will constitute your acceptance of such changes. If you do not wish to continue using the {pageInfo.company_shortname} Service under the updated Terms, you may terminate your account by contacting us. The effective date set forth at the top of this document indicates when these Terms were last changed.</p>
                            <h5 className="text-primary">Entire Agreement</h5>
                            <p>Other than as stated in this section or as explicitly agreed upon in writing between you and {pageInfo.company_shortname}, these Terms constitute all the terms and conditions agreed upon between you and {pageInfo.company_shortname} and supersede any prior agreements in relation to the subject matter of these Terms, whether written or oral.</p>
                            <h5 className="text-primary">Severability and Waiver</h5>
                            <p>
                                Unless as otherwise stated in these Terms, should any provision of these Terms be held invalid or unenforceable for any reason or to any extent, the remaining provisions of these Terms will not be affected, and the application of that provision shall be enforced to the extent permitted by law.<br />
                                Any failure by {pageInfo.company_shortname} or any third-party beneficiary to enforce these Terms or any provision thereof shall not waive {pageInfo.company_shortname}&apos;s or the applicable third-party beneficiary&apos;s right to do so.
                            </p>
                            <h5 className="text-primary">Assignment</h5>
                            <p>{pageInfo.company_shortname} may assign any or all of these Terms, and may assign or delegate, in whole or in part, any of its rights or obligations under these Terms. You may not assign these Terms, in whole or in part, nor transfer or sub-license your rights under these Terms, to any third party.</p>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PrivacyPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách bảo mật</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Chính sách bảo mật này công bố cách thức mà chúng tôi thu thập, lưu trữ và xử lý thông tin hoặc dữ liệu cá nhân (“Thông tin cá nhân”) của các khách hàng của mình thông qua Web. Chúng tôi cam kết sẽ bảo mật các thông tin cá nhân của khách hàng, đồng thời sẽ nỗ lực hết sức và sử dụng các biện pháp thích hợp để các thông tin mà khách hàng cung cấp cho chúng tôi trong quá trình sử dụng Web được bảo mật và bảo vệ khỏi sự truy cập trái phép.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Tuy nhiên, chúng tôi không đảm bảo ngăn chặn được tất cả các truy cập trái phép. Trong trường hợp truy cập trái phép nằm ngoài khả năng kiểm soát của chúng tôi, công ty sẽ không chịu trách nhiệm dưới bất kỳ hình thức nào đối với bất kỳ khiếu nại, tranh chấp hoặc thiệt hại nào phát sinh từ hoặc liên quan đến truy cập trái phép đó.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Để hiểu rõ hơn về chính sách trong công tác thu thập, lưu trữ và sử dụng thông tin cá nhân của người sử dụng Web này, vui lòng đọc các chính sách bảo mật dưới đây:</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>I. </span></span></strong><strong><span style={{ fontSize: '12.0pt' }}><span>&nbsp;Mục đích và phạm vi thu thập thông tin cá nhân</span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Để truy cập và sử dụng một số dịch vụ tại Web, bạn có thể sẽ được yêu cầu đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên, Số ĐT liên lạc …). Mọi thông tin khai báo phải đảm bảo tính chính xác và hợp pháp. Web của chúng tôi không chịu mọi trách nhiệm liên quan đến pháp luật của thông tin khai báo. Chúng tôi cũng có thể thu thập thông tin về số lần viếng thăm, bao gồm số trang bạn xem, số links (liên kết) bạn click và những thông tin khác liên quan đến việc kết nối đến Web của chúng tôi. Chúng tôi cũng thu thập các thông tin mà trình duyệt Web bạn sử dụng mỗi khi truy cập vào Web, bao gồm: địa chỉ IP, loại ngôn ngữ sử dụng, thời gian và những địa chỉ mà truy xuất đến.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>II. Phạm vi sử dụng thông tin</span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Các thông tin thu thập thông qua Web </span></span><span style={{ fontSize: '12.0pt' }}><span><span>shop.locamart.vn </span></span></span><span style={{ fontSize: '12.0pt' }}><span>sẽ giúp chúng tôi:</span></span></span></span></span></p>
                        <ul>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Hỗ trợ khách hàng khi mua sản phẩm</span></span></span></span></span></li>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Giải đáp thắc mắc khách hàng</span></span></span></span></span></li>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Thực hiện các bản khảo sát khách hàng</span></span></span></span></span></li>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của</span></span> <span style={{ fontSize: '12.0pt' }}><span>Web </span></span><span style={{ fontSize: '12.0pt' }}><span><span>shop.locamart.vn </span></span></span></span></span></span></li>
                        </ul>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>– Khách hàng cần đề nghị hỗ trợ hoặc có thắc mắc cần giải đáp muốn chuyển thông tin đến chúng tôi thông qua Web </span></span><span style={{ fontSize: '12.0pt' }}><span><span>shop.locamart.vn,</span></span></span><span style={{ fontSize: '12.0pt' }}><span> quý khách có thể sẽ được yêu cầu đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên, Số ĐT liên lạc…). Mọi thông tin khai báo phải đảm bảo tính chính xác và hợp pháp. </span></span><span style={{ fontSize: '12.0pt' }}><span>Web </span></span><span style={{ fontSize: '12.0pt' }}><span><span>shop.locamart.vn </span></span></span><span style={{ fontSize: '12.0pt' }}><span>không chịu mọi trách nhiệm liên quan đến pháp luật của thông tin khai báo.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>– Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để liên hệ trực tiếp với bạn dưới các hình thức như: gởi thư ngỏ, đơn đặt hàng, thư cảm ơn, thông tin về kỹ thuật và bảo mật, …..</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Chúng tôi</span></span><span style={{ fontSize: '12.0pt' }}><span> cam kết về việc sử dụng thông tin cá nhân của Khách hàng đúng với các mục đích và phạm vi đã thông báo. Trong trường hợp có bất kỳ phạm vi sử dụng phát sinh ngoài mục đích và thông báo, </span></span><span style={{ fontSize: '12.0pt' }}><span>chúng tôi</span></span><span style={{ fontSize: '12.0pt' }}><span> sẽ gửi thông báo cho Khách hàng và chỉ sử dụng khi có sự đồng ý của Khách hàng.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>III. Thời gian lưu trữ thông tin</span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Chúng tôi sẽ lưu trữ các thông tin cá nhân do khách hàng cung cấp trên các hệ thống nội bộ của chúng tôi trong quá trình cung cấp dịch vụ cho khách hàng hoặc cho đến khi hoàn thành mục đích thu thập hoặc khi khách hàng có yêu cầu hủy các thông tin đã cung cấp.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>IV. Những người hoặc tổ chức có thể được tiếp cận với thông tin</span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Khách hàng</span></span><span style={{ fontSize: '12.0pt' }}><span> đồng ý rằng: trong trường hợp cần thiết, các cơ quan/tổ chức/cá nhân sau có quyền được tiếp cận và thu thập các thông tin của mình, bao gồm:</span></span></span></span></span></p>
                        <ul>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Ban Quản Trị</span></span></span></span></span></li>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Cơ quan nhà nước có thẩm quyền</span></span></span></span></span></li>
                            <li style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Bên khiếu nại chứng minh được hành vi vi phạm của </span></span><span style={{ fontSize: '12.0pt' }}><span>Khách hàng</span></span><span style={{ fontSize: '12.0pt' }}><span> (nếu có)</span></span></span></span></span></li>
                        </ul>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>V. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>CÔNG TY CỔ PHẦN LOCAMART VIỆT NAM </span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>MST 0110485967 do Phòng Đăng ký kinh doanh - Sở Kế hoạch và Đầu tư thành phố Hà Nội Cấp ngày 25/09/2023.</span></span></span></span></span></p>
                        <p><span style={{ fontSize: '10pt' }}><span><span style={{ fontSize: '12.0pt' }}><span>Địa chỉ: Số 2 Văn Cao, Phường Thuỵ Khuê, Quận Tây Hồ, Thành phố Hà Nội, Việt Nam</span></span></span></span></p>
                        <p><span style={{ fontSize: '10pt' }}><span><span style={{ fontSize: '12.0pt' }}><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span>✆</span></span></span><span style={{ fontSize: '12.0pt' }}><span> Hotline: 0903287666</span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span>✉</span></span></span><span style={{ fontSize: '12.0pt' }}><span> Email: support@locamos.com</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>V</span></span></strong><strong><span style={{ fontSize: '12.0pt' }}><span>I</span></span></strong><strong><span style={{ fontSize: '12.0pt' }}><span>. Cam kết bảo mật thông tin cá nhân </span></span></strong><strong><span style={{ fontSize: '12.0pt' }}><span>khách hàng </span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Chúng tôi cam kết bảo mật thông tin cá nhân của bạn bằng mọi cách thức có thể. Chúng tôi sẽ sử dụng nhiều công nghệ bảo mật thông tin khác nhau nhằm bảo vệ thông tin này không bị truy lục, sử dụng hoặc tiết lộ ngoài ý muốn. Chúng tôi khuyến cáo bạn nên bảo mật các thông tin liên quan đến mật khẩu truy xuất của bạn và không nên chia sẻ với bất kỳ người nào khác. Nếu sử dụng máy tính chung nhiều người, bạn nên đăng xuất, hoặc thoát hết tất cả cửa sổ Web đang mở.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>VII. Cơ chế tiếp nhận và giải quyết khiếu nại liên quan đến thông tin cá nhân </span></span></strong></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Khách hàng</span></span><span style={{ fontSize: '12.0pt' }}><span> có quyền gửi khiếu nại về việc lộ thông tin cá nhân cho bên thứ 3 đến ban quản trị của</span></span> <span style={{ fontSize: '12.0pt' }}><span>Web </span></span><span style={{ fontSize: '12.0pt' }}><span><span>shop.locamart.vn </span></span></span><span style={{ fontSize: '12.0pt' }}><span>đến địa chỉ công ty hoặc qua Email: </span></span><span style={{ fontSize: '12.0pt' }}><span><a href="mailto:support@locamos.com" style={{ color: 'blue', textDecoration: 'underline' }}>support@locamos.com</a></span></span> &nbsp;</span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Công ty có trách nhiệm thực hiện các biện pháp kỹ thuật, nghiệp vụ để xác minh các nội dung được phản ánh.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><span style={{ fontSize: '12.0pt' }}><span>Thời gian xử lý phản ánh liên quan đến thông tin cá nhân là 7 ngày.</span></span></span></span></span></p>
                        <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '10pt' }}><span><span><strong><span style={{ fontSize: '12.0pt' }}><span>VII</span></span></strong><strong><span style={{ fontSize: '12.0pt' }}><span>. Thông tin liên hệ</span></span></strong></span></span></span></p>
                        <p><span style={{ fontSize: '10pt' }}><span><span style={{ fontSize: '12.0pt' }}><span>Chúng tôi luôn hoan nghênh các ý kiến đóng góp, liên hệ và phản hồi thông tin từ bạn về “Chính sách bảo mật” này. Nếu bạn có những thắc mắc liên quan xin vui lòng liên hệ theo Điện thoại: </span></span><span style={{ fontSize: '12.0pt' }}><span>0903287666,</span></span> <span style={{ fontSize: '12.0pt' }}><span>Email: </span></span><span style={{ fontSize: '12.0pt' }}><span><a href="mailto:support@locamos.com" style={{ color: 'blue', textDecoration: 'underline' }}>support@locamos.com</a></span></span> </span></span></p>
                        <p style={{ textAlign: 'justify' }}>&nbsp;</p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PaymentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách thanh toán</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tiền mặt&nbsp;(COD)</li>
                            <li>Thanh toán chuyển khoản</li>
                        </ol>
                        <table cellSpacing={0} className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Hình thức</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Diễn giải</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>1. Tiền mặt (COD)</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Khách hàng sẽ thanh toán bằng tiền mặt trực tiếp cho nhân viên giao hàng, hoặc thanh toán cho nhân viên bán hàng mua tại cửa hàng</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>2. Chuyển khoản</strong></p>
                                    </td>
                                    <td>
                                        <p>&nbsp; Sau khi hoàn tất đơn đặt hàng. Khách hàng vui lòng chuyển khoản về địa chỉ:</p>
                                        <p>&nbsp; - Số tài khoản: <a data-z-element-type="phone-number">785555888</a></p>
                                        <p><a data-z-element-type="phone-number">&nbsp; -&nbsp;</a>Tên chủ tài khoản: CONG TY CO PHAN LOCAMART VIET NAM</p>
                                        <p>&nbsp; - Tên ngân hàng: Ngân hàng TMCP Á Châu (ACB)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ShipmentPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách vận chuyển</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p>
                            <b>1. Phân định trách nhiệm của thương nhân, tổ chức cung ứng dịch vụ logistics về cung cấp chứng từ hàng hóa trong quá trình giao nhận</b><br /><br />
                            <b>Nghĩa vụ của bên vận chuyển</b><br />
                            - Bảo đảm vận chuyển tài sản đầy đủ, an toàn đến địa điểm đã định, theo đúng thời hạn<br />
                            - Giao tài sản cho người có quyền nhận<br />
                            -Chịu chi phí liên quan đến việc chuyên chở tài sản, trừ trường hợp có thỏa thuận khác<br />
                            - Mua bảo hiểm trách nhiệm dân sự theo quy định của pháp luật<br />
                            - Bồi thường thiệt hại cho bên thuê vận chuyển trong trường hợp bên vận chuyển để mất, hư hỏng tài sản, trừ trường hợp có thỏa thuận khác hoặc pháp luật có quy định khác<br /><br />
                            <b>Quyền của bên vận chuyển</b><br />
                            - Kiểm tra sự xác thực của tài sản, của vận đơn hoặc chứng từ vận chuyển tương đương khác<br />
                            - Từ chối vận chuyển tài sản không đúng với loại tài sản đã thỏa thuận trong hợp đồng<br />
                            - Yêu cầu bên thuê vận chuyển thanh toán đủ cước phí vận chuyển đúng thời hạn<br />
                            - Từ chối vận chuyển tài sản cấm giao dịch, tài sản có tính chất nguy hiểm, độc hại<br /><br />
                            <b>Nghĩa vụ bên thuê vận chuyển</b><br />
                            - Trả đủ tiền cước phí vận chuyển cho bên vận chuyển theo đúng thời hạn, phương thức thỏa thuận<br />
                            - Cung cấp thông tin cần thiết liên quan đến tài sản vận chuyển để đảm bảo an toàn cho tài sản vận chuyển<br />
                            - Trông coi tài sản trên đường vận chuyển, nếu có thỏa thuận. Trường hợp bên thuê vận chuyển trông coi tài sản mà tài sản bị mất, hư hỏng thì không được bồi thường
                            Quyền của bên thuê vận chuyển<br />
                            - Yêu cầu bên vận chuyển chuyên chở tài sản đến đúng địa điểm, thời điểm đã thỏa thuận<br />
                            - Trực tiếp hoặc chủ định người thứ ba nhận lại tài sản đã thuê vận chuyển<br /><br />
                            <b>Trách nhiệm bồi thường thiệt hại</b><br />
                            - Bên vận chuyển phải bồi thường thiệt hại cho bên thuê vận chuyển nếu tài sản bị mất hoặc hư hỏng<br />
                            - Bên thuê vận chuyển phải bồi thường thiệt hại cho bên vận chuyển và người thứ ba về thiệt hại do tài sản có tính chất nguy hiểm, độc hại mà không có biện pháp đóng gói, bảo đảm an toàn trong quá trình vận chuyển<br />
                            - Trường hợp bất khả kháng dẫn đến tài sản vận chuyển bị mất, hư hỏng hoặc bị hủy hoại trong quá trình vận chuyển thì bên vận chuyển không phải chịu trách nhiệm bồi thường thiệt hại, trừ trường hợp có thỏa thuận khác hoặc pháp luật có quy định khác.<br /><br />
                        </p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><strong><span>2. Thời gian giao hàng</span></strong><strong><span>: </span></strong><span>2-5 ngày làm việc đối với các thành phố,&nbsp;huyện/ xã trên khắp đất nước</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>- Đối với hình thức chuyển khoản, quý khách vui lòng chuyển trước giá trị đơn đặt hàng cho&nbsp;<strong>shop.locamart.vn, </strong>Các tài khoản có sử dụng SMS Banking nên sẽ nhận được thông tin ngay lập tức.</span></span><br />
                            <span><strong><span>shop.locamart.vn </span></strong><span>sẽ ngay lập tức xác nhận thông tin và chuyển hàng cho khách!</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trách nhiệm của bên giao hàng (cung cấp dịch vụ Logistic): chịu trách nhiệm về thời gian giao hàng, tình trạng hàng hóa đảm bảo khi đến tay khách hàng. Trường hợp xảy ra sự cố do lỗi của bên giao hàng hoàn toàn chịu trách nhiệm và chịu bồi thường theo giá trị sản phẩm. <strong>shop.locamart.vn, </strong>sẽ không chịu trách nhiệm do lỗi phát sinh chủ quan của bên giao hàng, trường hợp bất khả kháng </span></span><span><span>chúng tôi</span></span><span><span> sẽ hỗ trợ 50% tổn thất. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>-Trường hợp phát sinh chậm trễ trong việc giao hàng, <strong>shop.locamart.vn </strong>sẽ liên hệ, thông tin kịp thời cho khách hàng và tạo cơ hội để khách hàng có thể hủy hợp đồng nếu muốn.</span></span></span></span></p>
                        <p>
                            <br /><b>3. Giao hàng chậm</b><br /><br />
                            - Trong trường hợp có thỏa thuận về thời điểm giao hàng, bên vận chuyển có nghĩa vụ phải giao hàng vào đúng thời điểm giao hàng đã thỏa thuận trong hợp đồng<br />
                            - Theo đó, khi đối tác giao hàng sau thời hạn hoặc thời điểm đã thảo thuận trong hợp đồng sẽ cấu thành hành vi giao hàng chậm. Hành vi này được coi là hành vi vi phạm nghiêm trọng nghĩa vụ trong hợp đồng của bên vận chuyển. Nếu hết thời hạn giao hàng thì bên vận sẽ bị xem là đã vi phạm hợp đồng và bên thuê vận chuyển có quyền từ chối việc thanh toán. Theo đó, hợp đồng không thể được tiếp tục thực hiện trên thực tế nếu không có sự thỏa thuận khác từ hai bên<br />
                            - Việc phát sinh chậm trễ trong vấn đề giao hàng hoặc cung ứng dịch vụ thương nhân, tổ chức, cá nhân phải có thông tin kịp thời cho Khách Hàng ( thời hạn tối thiểu trước…. giờ), cung cấp thông lý do cụ thể về sự chậm trễ và thông tin chi tiết về những biện pháp đang được thực hiện để khắc phục tình trạng chậm trễ và  tạo cơ hội để Khách hàng có thể hủy hợp đồng nếu muốn, và các quy trình liên quan việc này.
                        </p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const InspectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách kiểm hàng</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khi khách hàng nhận hàng và kiểm tra kĩ sản phẩm trước khi nhận (quý khách nên quay video trong quá trình mở hộp nhận hàng) và phản hồi ngay cho nhân viên giao hàng, bên chúng tôi sẽ tiến hành khắc phục đổi cho khách hàng.</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Nếu phát sinh những vấn đề ngoài ý muốn về phía Khách hàng, </span><span>shop.locamart.vn </span><span>sẽ hỗ trợ linh động giải quyết trong các trường hợp:</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Địa chỉ không chính xác</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Khách muốn hẹn lịch giao hàng</span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Những trường hợp bất khả kháng như thiên tai, tai nạn giao thông… shop.locamart.vn sẽ luôn hỗ trợ và đảm bảo quyền lợi của Quý Khách. </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span><span>Mọi thắc mắc và góp ý vui lòng liên hệ</span><span>: </span></span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✆</span></span><span style={{ color: '#212338' }}> Hotline: 0903287666</span></span></span></p>
                        <p><span style={{ fontSize: '12pt' }}><span><span style={{ fontFamily: '"Segoe UI Symbol",sans-serif' }}><span style={{ color: '#212338' }}>✉</span></span><span style={{ color: '#212338' }}> Email: </span><a href="mailto:support@locamos.com" style={{ color: '#0563c1', textDecoration: 'underline' }}>support@locamos.com</a> </span></span></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ReturnPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách đổi trả</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Tất cả các sản phẩm của LocaMart được áp dụng đổi trả trong 2h tính từ thời điểm mua hàng.</li>
                            <li>Khi Khách hàng đến cửa hàng đổi trả hoặc yêu cầu nhân sự LocaMart nhận lại hàng cần có đầy đủ:
                                <ul>
                                    <li>Hóa đơn mua hàng</li>
                                    <li>Sản phẩm lỗi hỏng (nếu Khách Hàng gửi hình ảnh thì chỉ chấp nhận hình ảnh sản phẩm lỗi đối với các lỗi có thể quan sát bằng mắt thường như: Hàng mốc, thối, dập…)</li>
                                </ul>
                            </li>
                            <li>Chính sách đổi trả sẽ không áp dụng trong các trường hợp sau:
                                <ul>
                                    <li>Quý khách tự làm ảnh hưởng đến tình trạng bên ngoài như rách bao bì, bong tróc, bể vỡ, rơi rụng…</li>
                                    <li>Quý khách không thực hiện đúng theo cách thức bảo quản dẫn tới việc hư hỏng sản phẩm.</li>
                                </ul>
                            </li>
                            <li>Cách thức thu hồi hàng, hoàn trả tiền khi đổi trả hàng lỗi:
                                <ul>
                                    <li>Trường hợp Khách Hàng đến cửa hàng để đổi trả và hàng hóa đáp ứng điều kiện đổi trả, cửa hàng sẽ tiến hành đổi trả hàng thay thế ngay cho Khách Hàng.</li>
                                    <li>Đối với Khách Hàng khiếu nại qua hình thức khác (như qua tổng đài, fanpage…), chậm nhất 2h kể từ khi tiếp nhận yêu cầu đổi trả hàng của Khách Hàng, LocaMart sẽ liên hệ Khách hàng và tiến hành kiểm tra tình trạng hàng đổi trả.</li>
                                    <li>Trường hợp hàng hóa lỗi đáp ứng điều kiện đổi trả, LocaMart sẽ thu hồi hàng lỗi và đổi hàng hóa đạt yêu cầu thay thế cho Khách Hàng tương ứng với giá trị lượng hàng lỗi mà locaMart nhận được từ Khách Hàng. Nếu không còn hàng hóa để thay thế thì LocaMart sẽ hoàn tiền cho Khách Hàng bằng tiền mặt hoặc chuyển khoản.</li>
                                    <li>Tùy từng trường hợp, LocaMart có thể cử nhân sự hoặc thông qua một đối tác vận chuyển của mình để đến thu hồi hàng. Việc này sẽ được hướng dẫn, thỏa thuận cụ thể khi làm việc với Khách hàng và Khách hàng không phải trả thêm phí cho việc thu hồi hàng lỗi này.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const RightsProtectionPolicy = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Chính sách bảo đảm quyền lợi</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <ol>
                            <li>Khách hàng nhận rau hàng tuần 2kg/lần tại các điểm nhận rau</li>
                            <li>Rau cung cấp cho khách hàng đc tuyển chọn từ Mộc Châu và Đà Lạt, đạt chuẩn VietGAP</li>
                            <li>Gói bảo hiểm trị giá 10 triệu đồng trong trường hợp sức khỏe khách hàng bị ảnh hưởng do sử dụng rau do LocaMart cung cấp</li>
                        </ol>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const VegetableCard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <img src="/public_assets/images/slide/Slide1.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide2.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide3.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide4.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide5.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide6.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide7.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide8.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide9.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide10.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide11.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                    <img src="/public_assets/images/slide/Slide12.jpeg" alt="Tem Rau LocaMart" className="img-fluid w-100 mb-3"></img>
                </div>
            </header>
        </ScreenWithNavBarAndFooter>
    )
})

const GuideVNPAY = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Hướng dẫn thanh toán VNPAY trên Website</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <p>Cổng thanh toán VNPAY là giải pháp thanh toán do Công ty Cổ phần Giải pháp Thanh toán Việt Nam (VNPAY) phát triển. Khách hàng sử dụng thẻ/tài khoản ngân hàng, tính năng QR Pay/VNPAY-QR được tích hợp sẵn trên ứng dụng Mobile Banking của các ngân hàng hoặc Ví điện tử liên kết để thanh toán các giao dịch và nhập mã giảm giá (nếu có)</p>
                        <h3 style={{ textAlign: 'center' }}><span><span><span><span><strong>Quét mã VNPAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví điện tử liên kết</strong></span></span></span></span></h3>
                        <p><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590647_YuPrwc0N93G14TUV_1699593095-phpxh92pm.png" style={{ height: '785px', maxWidth: '100%', width: '1330px' }} /></p>
                        <p style={{ textAlign: 'center' }}><span><span><span><span><strong>40+ Thẻ ATM/nội địa/tài khoản ngân hàng&nbsp;</strong></span></span></span></span></p>
                        <p><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590747_cunFTNBvlmCd2WUH_1699592104-phplvtyjz.png" style={{ height: '648px', width: '1724px' }} /></p>
                        <p style={{ textAlign: 'center' }}><span><span><span><span><strong>4 Thẻ thanh toán quốc tế</strong></span></span></span></span></p>
                        <p><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590776_avPoM7Jq8ITLXnm5_1699597613-phpoqoqan.png" style={{ height: '103px', width: '850px' }} /></p>
                        <p style={{ textAlign: 'center' }}><span><span><span><span><strong>Các phương thức thanh toán qua VNPAY</strong></span></span></span></span></p>
                        <p><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590804_kf4CzSsb5JIYGD0V_1699600328-php6riswn.png" style={{ height: '648px', width: '806px' }} /></p>
                        <p><span><span><span><span><strong>1. Phương thức thanh toán qua “Ứng dụng thanh toán hỗ trợ VNPAY-QR”</strong></span></span></span></span></p>
                        <p><span><span><span><span><strong>Bước 1</strong></span></span></span></span><span><span><span><span>: Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng </span></span></span></span><br />
                            <span><span><span><span>Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”.</span></span></span></span></p>
                        <p><span><span><span><span><strong>Bước 2</strong></span></span></span></span><span><span><span><span>: Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức&nbsp; “Ứng dụng thanh toán hỗ trợ VNPAY-QR”</span></span></span></span></p>
                        <p><span><span><span><span><strong>Bước 3</strong></span></span></span></span><span><span><span><span>: Hệ thống hiển thị mã QR cùng với số tiền cần thanh toán, Quý khách kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo danh sách liệt kê), chọn </span></span></span></span><span><span><span><span><strong>“Quét Mã”</strong></span></span></span></span><span><span><span><span> và tiến hành quét mã QR trên màn hình thanh toán website</span></span></span></span></p>
                        <p><span><span><span><span><em>*Lưu ý: Mã QR có hiệu lực trong 15 phút </em></span></span></span></span><br />
                            <span><span><span><span><em>Để quá trình thanh toán thành công, khách hàng vui lòng tham khảo trước các điều kiện và thao tác quét mã trên điện thoại để sẵn sàng, tránh sự cố hết thời gian ảnh hưởng đến thanh toán và mã khuyến mại của quý khách.</em></span></span></span></span></p>
                        <p><br />
                            <span><span><span><span><strong>Bước 4</strong></span></span></span></span><span><span><span><span>: Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh toán</span></span></span></span><br />
                            <span><span><span><span>Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn hàng đặt hàng thành công tại website</span></span></span></span></p>
                        <p><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590850_NdW7ojvMIgS6KxiC_1699595439-phpbwpvjo.png" style={{ height: '668px', width: '1442px' }} /></p>
                        <p>&nbsp;</p>
                        <p><span><span><span><span><strong>2. Phương thức thanh toán qua “Thẻ nội địa và tài khoản ngân hàng”</strong></span></span></span></span><br />
                            <span><span><span><span><strong>Bước 1</strong></span></span></span></span><span><span><span><span>: Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng </span></span></span></span><br />
                            <span><span><span><span>Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh toán VNPAY và nhấn nút “Đặt hàng ngay”.</span></span></span></span><br />
                            <span><span><span><span><strong>Bước 2</strong></span></span></span></span><span><span><span><span>: Màn hình thanh toán chuyển sang giao diện cổng thanh toán VNPAY. Chọn phương thức&nbsp; “Thẻ nội địa và tài khoản ngân hàng” và chọn ngân hàng muốn thanh toán thẻ trong danh sách&nbsp;</span></span></span></span><br />
                            <span><span><span><span><strong>Bước 3</strong></span></span></span></span><span><span><span><span>: Quý khách vui lòng thực hiện nhập các thông tin thẻ/tài khoản theo yêu cầu và chọn “Tiếp tục”. Mã OTP sẽ được gửi về điện thoại đăng ký, nhập mã OTP để hoàn tất giao dịch&nbsp;</span></span></span></span></p>
                        <p><span><span><span><span><em>*Lưu ý: Giao dịch sẽ hết hạn sau 15 phút&nbsp;</em></span></span></span></span></p>
                        <p><span><span><span><span><strong>Bước 4</strong></span></span></span></span><span><span><span><span>: Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông báo xác nhận đơn hàng đặt hàng thành công tại website</span></span></span></span></p>
                        <p><span><span><span><span><img alt="" src="https://cdn.abphotos.link/photos/resized/x/2023/11/10/1699590895_grtUmflahVuwDXpb_1699592208-phprwbmvj.png" style={{ height: '677px', width: '1999px' }} /></span></span></span></span></p>
                        <p>&nbsp;</p>
                        <p><span><span><span><span><strong>3. Phương thức thanh toán qua “Thẻ thanh toán quốc tế (Visa, MasterCard, JCB, UnionPay)”</strong></span></span></span></span><br />
                            <span><span><span><span>Tương tự như phương thức thanh toán “Thẻ nội địa và tài khoản ngân hàng”</span></span></span></span></p>
                        <p><span><span><span><span><strong>4. Phương thức thanh toán qua “Ví điện tử VNPAY”</strong></span></span></span></span></p>
                        <p><span><span><span><span>Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ VNPAY-QR”</span></span></span></span></p>
                        <p>&nbsp;</p>
                        <p><span><span><span><span><strong>---------------------</strong></span></span></span></span><br />
                            <span><span><span><span><strong>KÊNH HỖ TRỢ</strong></span></span></span></span><br />
                            <span><span><span><span>- Tổng đài: *3388 hoặc 1900 55 55 77&nbsp;</span></span></span></span></p>
                        <p><span><span><span><span>- Zalo OA: </span></span></span></span><a href="https://zalo.me/4134983655549474109" style={{ textDecoration: 'none' }}><span><span><span style={{ color: '#1155cc' }}><span><u>zalo.me/4134983655549474109</u></span></span></span></span></a></p>
                        <p><span><span><span><span>- Email: </span></span></span></span><a href="mailto:hotro@vnpay.vn" style={{ textDecoration: 'none' }}><span><span><span style={{ color: '#1155cc' }}><span><u>hotro@vnpay.vn</u></span></span></span></span></a></p>
                        <p><span><span><span><span>- Fanpage: </span></span></span></span><a href="https://www.facebook.com/VNPAYQR.vn" style={{ textDecoration: 'none' }}><span><span><span style={{ color: '#1155cc' }}><span><u>facebook.com/VNPAYQR.vn</u></span></span></span></span></a></p>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const Template = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">{pageInfo.company_shortname} - Chính sách bảo mật</h2>
                </div>
            </header>
            <section className="page-section content-page-section">
                <div className="container-xl">
                    <div className="sec-content">
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    TermsOfService,
    PrivacyPolicy,
    PaymentPolicy,
    ShipmentPolicy,
    InspectionPolicy,
    ReturnPolicy,
    RightsProtectionPolicy,
    VegetableCard,
    GuideVNPAY
};