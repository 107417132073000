import { API, Request } from 'network';

const fetch_nearby_store = (params) => {
    const request = new Request();
    
    return request.get(API.FETCH_NEARBY_STORE, params, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_NEARBY_STORE + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return null;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_NEARBY_STORE + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const check_user_referer = (data) => {
    const request = new Request();

    const post_data = JSON.stringify(data);
    
    return request.post(API.CHECK_USER_REFFERER, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.CHECK_USER_REFFERER + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.CHECK_USER_REFFERER + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const active_membership = (data) => {
    const request = new Request();

    const post_data = JSON.stringify({
        ref_id: data.ref_id,
        token: data.token,
        full_name: encodeURIComponent(data.full_name),
        phone: data.phone,
        address: encodeURIComponent(data.address + ', ' + data.district_text + ', ' + data.province_text) + '|' + data.province_id + '|' + data.district_id,
        store_id: data.store_id,
        day_delivery: data.delivery_day,
        code_card: data.membercard_id,
        payment_type: 'ACTIVE_CODE_CARD',
        id: data.membership_id
    });
    
    return request.post(API.ACTIVE_MEMBERSHIP, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {

            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.ACTIVE_MEMBERSHIP + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.ACTIVE_MEMBERSHIP + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const buy_membership = (data) => {
    const request = new Request();

    const post_data = JSON.stringify({
        ref_id: data.ref_id,
        token: data.token,
        full_name: encodeURIComponent(data.full_name),
        phone: data.phone,
        address: encodeURIComponent(data.address + ', ' + data.district_text + ', ' + data.province_text) + '|' + data.province_id + '|' + data.district_id,
        store_id: data.store_id,
        day_delivery: data.delivery_day,
        payment_method: data.payment_method,
        membership_package: parseInt(data.membership_package),
        use_discount: data.use_discount,
        id: data.membership_id
    });
    
    return request.post(API.CREATE_ORDER, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {

            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.CREATE_ORDER + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.CREATE_ORDER + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const fetch_order_info = (data) => {
    const request = new Request();

    const post_data = JSON.stringify(data);
    
    return request.post(API.FETCH_ORDER_VNPAY_INFO, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {

            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_ORDER_VNPAY_INFO + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_ORDER_VNPAY_INFO + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const update_order = (data) => {
    const request = new Request();

    const form_data = new FormData();
    form_data.append('file', data.file);
    
    return request.post(API.UPLOAD_IMAGE, form_data, {
        headers: { 
            'Content-Type': 'multipart/form-data'
        },
        params: {
            token: data.token,
            id: data.id
        }
    }).then(result => {
        console.log(API.UPLOAD_IMAGE, result);
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.UPLOAD_IMAGE + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.UPLOAD_IMAGE + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const fetch_user_redeem_history = (data) => {
    const request = new Request();

    const post_data = JSON.stringify(data);
    
    return request.post(API.FETCH_USER_REDEEM_HISTORY, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_USER_REDEEM_HISTORY + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_USER_REDEEM_HISTORY + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const fetch_user_commissions = (params) => {
    const request = new Request();
    
    return request.get(API.FETCH_USER_COMMISSIONS, params).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_USER_COMMISSIONS + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return null;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_USER_COMMISSIONS + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const update_membership_info = (data) => {
    const request = new Request();

    const post_data = JSON.stringify({
        token: data.token,
        full_name: encodeURIComponent(data.full_name),
        phone: data.phone,
        address: encodeURIComponent(data.address + ', ' + data.district_text + ', ' + data.province_text) + '|' + data.province_id + '|' + data.district_id,
        day_delivery: data.delivery_day,
        store_id: data.store_id,
        ship_type: data.ship_type,
        status_redeem: data.status_redeem
    });
    
    return request.post(API.UPDATE_USER_INFO, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {

            if (result.status === 200) {
                return 'SUCCESS';
            } else {
                console.log('API ' + API.UPDATE_USER_INFO + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.UPDATE_USER_INFO + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const fetch_user_referrals_request = new Request();
const fetch_user_referrals = (params) => {
    fetch_user_referrals_request.cancel();

    let search_params = {
        token: params.token,
        page: params.page,
        size: params.size
    }

    console.log(params.user_id && params.user_id !== '', params.keyword && params.keyword !== '');

    if (params.user_id && params.user_id !== '') {
        search_params['user_id'] = params.user_id;
    }

    if (params.keyword && params.keyword !== '') {
        search_params['keyword'] = params.keyword;
    }
    
    return fetch_user_referrals_request.get(API.FETCH_USER_REFFERALS, search_params).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_USER_REFFERALS + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return null;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_USER_REFFERALS + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const fetch_referral_revenue_request = new Request();
const fetch_referral_revenue = (id, params) => {
    fetch_referral_revenue_request.cancel();
    
    return fetch_referral_revenue_request.get(API.FETCH_REFERRAL_REVENUE + id, params).then(result => {
        try {
            if (result.status === 200) {
                return result.data;
            } else {
                console.log('API ' + API.FETCH_REFERRAL_REVENUE + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return null;
            }
        } catch (error) {
            console.log('API ' + API.FETCH_REFERRAL_REVENUE + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

export const membershipService = {
    fetch_nearby_store,
    check_user_referer,
    active_membership,
    buy_membership,
    fetch_order_info,
    update_order,
    fetch_user_redeem_history,
    fetch_user_commissions,
    update_membership_info,
    fetch_user_referrals,
    fetch_referral_revenue
}